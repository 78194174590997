import React from 'react';
import PropTypes from 'prop-types';
import { graphql, Link } from 'gatsby';
import { Container, Row, Col } from 'react-grid-system';
import Img from 'gatsby-image';
import styled from 'styled-components';
import Title from 'components/title';
import Head from 'components/head';
import MarkdownWrapper from 'components/markdownwrapper';
import Layout from 'components/layout';

require('../assets/css/breadcrumb.css');

const Wrapper = styled.div`
  margin: -4rem -15px 4rem -15px;
  display: block;
`;

const ArticlePost = ({ data }) => {
  const post = data.markdownRemark;
  return (
    <Layout>
      <Head
        pageTitle={post.frontmatter.title}
        siteDescription={
          post.frontmatter.metaDescription
            ? post.frontmatter.metaDescription
            : post.excerpt
        }
      />
      <Container fluid>
        <Row>
          <Col col={12}>
            <Wrapper>
              <Img
                fluid={
                  post.frontmatter.featuredImage
                    ? post.frontmatter.featuredImage.childImageSharp.fluid
                    : {}
                }
                alt={
                  post.frontmatter.teaserImageAltText
                    ? post.frontmatter.teaserImageAltText
                    : {}
                }
              />
            </Wrapper>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row justify="center">
          <Col col={12} sm={10} md={8}>
            <ol className="breadcrumb">
              <li className="breadcrumb__item">
                <Link to="/" className="breadcrumb__link">
                  Home
                </Link>
              </li>
              <li className="breadcrumb__item">
                <Link to="/news/" className="breadcrumb__link">
                  News
                </Link>
              </li>
              <li className="breadcrumb__item breadcrumb__item-last">
                <Link
                  to={post.frontmatter.slug}
                  className="breadcrumb__link"
                  aria-current="page"
                >
                  {post.frontmatter.title}
                </Link>
              </li>
            </ol>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row justify="center">
          <Col col={12} sm={10} md={8}>
            <MarkdownWrapper>
              <Title as="h1" size="large">
                {post.frontmatter.title}
              </Title>
              <div dangerouslySetInnerHTML={{ __html: post.html }} />
            </MarkdownWrapper>
          </Col>
        </Row>
      </Container>
    </Layout>
  );
};

ArticlePost.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ArticlePost;

export const query = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        title
        slug
        metaDescription
        teaserImageAltText
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 1240, maxHeight: 400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
      excerpt
    }
  }
`;
